import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";

import endpoints from "./endpoints";
import { displayToast } from "../helpers/toast";

const api = axios.create({
  timeout: 30000,
});

api.interceptors.request.use((config) => {
  if (config.url !== endpoints.login) {
    const token = localStorage.getItem("jwt");
    if (token) {
      config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    }
  }
  return config;
});

const handleError = (error: AxiosError, isLogin?: boolean) => {
  console.log(error);
  if (error.response) {
    const errorMsg = (error as any)?.response?.data?.message;
    if (errorMsg === "Unauthorized") {
      if (isLogin) {
        displayToast({ message: errorMsg, statusCode: 400 });
      } else {
        displayToast({ message: "Inicia sesión para ver esta página", statusCode: 400 });
        localStorage.removeItem("jwt");
        window.location.replace("/");
      }
    } else {
      displayToast({
        message: Array.isArray(errorMsg) ? errorMsg.join(", ") : errorMsg,
        statusCode: 400,
      });
    }
  } else if (error.request) {
    // La petición fue hecha pero no se recibió respuesta
    displayToast({ message: "No se recibió respuesta del servidor", statusCode: 400 });
  } else {
    // Ocurrió un error al configurar la petición
    displayToast({ message: "Error al configurar la petición", statusCode: 400 });
  }
};

export function useApiQuery<TData = unknown, TError = AxiosError>(
  key: string[],
  url: string,
  options?: Omit<UseQueryOptions<TData, TError, TData, QueryKey>, "queryKey" | "queryFn">,
  config?: AxiosRequestConfig
) {
  return useQuery<TData, TError>({
    queryKey: key,
    queryFn: async () => {
      const response = await api.get<TData>(url, config);
      return response.data;
    },
    ...options,
    throwOnError: (error) => {
      handleError(error as AxiosError);
      return false;
    },
  });
}

export function useApiMutation<TData = unknown, TVariables = unknown, TError = AxiosError>({
  url,
  method = "post",
  options,
  config,
  msgOnSuccess,
  isLogin = false,
}: {
  url: string;
  method?: "post" | "put" | "delete";
  options?: UseMutationOptions<TData, TError, TVariables>;
  config?: AxiosRequestConfig;
  msgOnSuccess?: string;
  isLogin?: boolean;
}) {
  return useMutation<TData, TError, TVariables>({
    mutationFn: async (variables) => {
      const response = await api[method]<TData>(url, variables, config);
      return response.data;
    },
    ...options,
    onError: (error) => {
      handleError(error as AxiosError, isLogin);
    },
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
      if (msgOnSuccess) {
        displayToast({ message: msgOnSuccess, statusCode: 200 });
      }
    },
  });
}
