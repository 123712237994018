import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Backdrop, CircularProgress, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { ApplicationType, Category_N, Category_S } from "../../../types/Application";
import { Field } from "../../../components/Field";
import { NotFound } from "../../layouts/NotFound";
import {
  CATEGORY,
  CATEGORY_SUB,
  DNI_TYPE,
  EDUCATION,
  HEAT_SOURCE,
  MARITAL_STATUS,
  MATERIAL,
  PROBLEMATIC,
  RELATIONSHIP,
  RESOURCE_TYPE,
  ROOF,
  TENURE,
  WATER_SUPPLY,
} from "../../../assets/constants/translates";
import { convertToDate } from "../../../helpers/dateFormat";
import { paperStyle } from "../../../assets/styles/Styles";
import { LoadingButton } from "@mui/lab";
import { displayToast } from "../../../helpers/toast";

export const Application = ({ fromSummary = false }: { fromSummary?: boolean }) => {
  const { idApplication } = useParams();
  const [resourceToGet, setResourceToGet] = useState("");
  const [loading, setLoading] = useState({ form: false, note: false, receipt: false });
  const {
    data: dataApplication,
    loading: loadingApplication,
    error: errorApplication,
  } = useAxios<ApplicationType>({
    url: `${endpoints.getApplicationById}/${idApplication}`,
  });
  const {
    data: resourceData,
    loading: loadingGetResource,
    refetch: getResource,
  } = useAxios<ArrayBuffer>({
    url: `${endpoints.downloadFile}/${resourceToGet}`,
    method: "get",
    config: { responseType: "arraybuffer" },
    awaiting: true,
  });
  const { data: formData, refetch: getForm } = useAxios<ArrayBuffer>({
    url: `${endpoints.getApplications}/${dataApplication.id}/ficha-socioeconomica`,
    method: "get",
    config: { responseType: "arraybuffer" },
    awaiting: true,
  });
  const { data: noteData, refetch: getNote } = useAxios<ArrayBuffer>({
    url: `${endpoints.getApplications}/${dataApplication.id}/nota`,
    method: "get",
    config: { responseType: "arraybuffer" },
    awaiting: true,
  });
  const { data: receiptData, refetch: getReceipt } = useAxios<ArrayBuffer>({
    url: `${endpoints.getApplications}/${dataApplication.id}/recibo`,
    method: "get",
    config: { responseType: "arraybuffer" },
    awaiting: true,
  });

  function getFile(type: string, name: string) {
    setResourceToGet(`${type}/${name}`);
  }
  function createResponse(data: ArrayBuffer, type: string) {
    try {
      var blob = new Blob([data], { type });
      var win = window.open("", "_blank") as Window;
      var URL = window.URL || window.webkitURL;
      var dataUrl = URL.createObjectURL(blob);
      win.location = dataUrl;
    } catch (error) {
      displayToast({
        message: "Falló la generación del documento. Inténtelo nuevamente",
        statusCode: 400,
      });
    }
  }

  useEffect(() => {
    if (resourceToGet) {
      getResource();
    }
  }, [resourceToGet]);
  useEffect(() => {
    if (formData) {
      createResponse(formData, "application/pdf");
      setLoading({ ...loading, form: false });
    }
  }, [formData]);
  useEffect(() => {
    if (noteData) {
      createResponse(noteData, "application/pdf");
      setLoading({ ...loading, note: false });
    }
  }, [noteData]);
  useEffect(() => {
    if (receiptData) {
      createResponse(receiptData, "application/pdf");
      setLoading({ ...loading, receipt: false });
    }
  }, [receiptData]);
  useEffect(() => {
    if (resourceData) {
      const ext = resourceToGet.split(".").pop();
      let content = "";
      switch (ext) {
        case "pdf":
          content = "application/pdf";
          break;
        case "docx":
          content = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "doc":
          content = "application/msword";
          break;
        default:
          content = "image/" + ext;
          break;
      }
      createResponse(resourceData, content);
      setResourceToGet("");
    }
  }, [resourceData]);

  return loadingApplication ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : errorApplication ? (
    <NotFound />
  ) : (
    <Paper sx={fromSummary ? { p: { xs: 2, md: 3 }, width: "100%" } : paperStyle}>
      <Typography variant="h3" gutterBottom>
        Resumen de la solicitud
      </Typography>
      <Typography variant="body1">
        Operador: {dataApplication.operator.name} {dataApplication.operator.lastName}
      </Typography>
      <Divider />
      <Typography variant="h4">Ciudadano #{dataApplication.person.id}</Typography>
      <Grid container spacing={2}>
        <Field
          description="Nombre completo"
          text={
            <Link underline="hover" href={`../ciudadano/${dataApplication.person.id}`}>
              {dataApplication.person.lastName}, {dataApplication.person.name}
            </Link>
          }
          xs={9}
        />
        <Field
          description={DNI_TYPE.find((value) => value.value === dataApplication.person.dniType)?.text as string}
          text={dataApplication.person.dni}
          xs={3}
        />
        <Field description="Dirección" text={dataApplication.form.address} xs={4} />
        <Field description="Estado civil" text={MARITAL_STATUS.find((value) => value.value === dataApplication.form.maritalStatus)?.text as string} xs={4} />
        <Field
          description="Máximo grado educativo alcanzado"
          text={EDUCATION.find((value) => value.value === dataApplication.form.education)?.text as string}
          xs={4}
        />
        <Field description="Ocupación" text={dataApplication.form.job.name} xs={4} />
        <Field description="Lugar de trabajo" text={dataApplication.form.workplace} xs={4} />
        <Field description="Ingresos" text={`$ ${dataApplication.form.earnings.toString()}`} xs={4} />
        {dataApplication.person.dniFileFront && (
          <Grid item xs={6}>
            <LoadingButton loading={loadingGetResource} onClick={() => getFile("dni", `${dataApplication.person.dniFileFront}`)}>
              {dataApplication.person.dniFileBack ? "Frente del DNI" : "Foto del DNI"}
            </LoadingButton>
          </Grid>
        )}
        {dataApplication.person.dniFileBack && (
          <Grid item xs={6}>
            <LoadingButton loading={loadingGetResource} onClick={() => getFile("dni", `${dataApplication.person.dniFileBack}`)}>
              Dorso del DNI
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      <Divider />
      {dataApplication.form.cohabitants.length > 0 && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h4">Grupo familiar</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {dataApplication.form.cohabitants.map((cohabitant) => {
                return (
                  <React.Fragment key={cohabitant.id}>
                    <Divider />
                    <Typography variant="h4">
                      {((cohabitant.name +
                        " " +
                        cohabitant.lastName +
                        " (" +
                        RELATIONSHIP.find((value) => value.value === cohabitant.relationship)?.text) as string) + ")"}
                      <small></small>
                    </Typography>
                    <Grid container spacing={2}>
                      <Field
                        description={DNI_TYPE.find((value) => value.value === cohabitant.dniType)?.text as string}
                        text={cohabitant.dni ? cohabitant.dni : "N/A"}
                        xs={2}
                      />
                      <Field description="Fecha de nacimiento" text={cohabitant.birthDate ? convertToDate(cohabitant.birthDate)[0] : "N/A"} xs={2} />
                      <Field
                        description="Máximo grado educativo alcanzado"
                        text={EDUCATION.find((value) => value.value === cohabitant.education)?.text as string}
                        xs={3}
                      />
                      <Field description="Ocupación" text={cohabitant.job.name} xs={3} />
                      <Field description="Ingresos" text={`$ ${cohabitant.earnings.toString()}`} xs={2} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}
      <Typography variant="h4">Datos de la vivienda</Typography>
      <Grid container spacing={2}>
        <Field description="Material" text={MATERIAL.find((value) => value.value === dataApplication.form.material)?.text as string} xs={4} />
        <Field description="Techo" text={ROOF.find((value) => value.value === dataApplication.form.roof)?.text as string} xs={4} />
        <Field description="Tenencia" text={TENURE.find((value) => value.value === dataApplication.form.tenure)?.text as string} xs={4} />
        <Field description="Servicio eléctrico" text={dataApplication.form.hasElectricity ? "Sí" : "No"} xs={4} />
        <Field
          description="Abastecimiento de agua"
          text={WATER_SUPPLY.find((value) => value.value === dataApplication.form.waterSupply)?.text as string}
          xs={4}
        />
        <Field description="Fuente calórica" text={HEAT_SOURCE.find((value) => value.value === dataApplication.form.heatSource)?.text as string} xs={4} />
      </Grid>
      <Divider />
      <Typography variant="h4">Datos de la solicitud</Typography>
      <Grid container spacing={2}>
        <Field
          description="Categoría"
          text={(CATEGORY[dataApplication.category as Category_N] || "") + ((CATEGORY_SUB[dataApplication.category as Category_S] && " (subsidio)") || "")}
          xs={4}
        />
        <Field description="Problemática" text={PROBLEMATIC.find((value) => value.value === dataApplication.problematic)?.text as string} xs={4} />
        <Field description="Monto otorgado" text={dataApplication.amount + " " + dataApplication.unit} xs={4} />
        <Field description="Observación y recomendación" text={dataApplication.observation} xs={12} />
        {dataApplication.isAccountable && dataApplication.status === "complete" && (
          <Alert severity="info" sx={{ width: "100%", mt: 2 }}>
            Para finalizar la solicitud por completo, la persona deberá presentar un comprobante
          </Alert>
        )}
      </Grid>
      <Divider />
      <Typography variant="h4">Documentación</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LoadingButton
            loading={loading.form}
            disabled={loading.note || loading.receipt}
            onClick={() => {
              setLoading({ ...loading, form: true });
              getForm();
            }}
          >
            Ficha socioeconómica
          </LoadingButton>
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            loading={loading.note}
            disabled={loading.form || loading.receipt}
            onClick={() => {
              setLoading({ ...loading, note: true });
              getNote();
            }}
          >
            Nota
          </LoadingButton>
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            loading={loading.receipt}
            disabled={loading.note || loading.form}
            onClick={() => {
              setLoading({ ...loading, receipt: true });
              getReceipt();
            }}
          >
            Recibo
          </LoadingButton>
        </Grid>
        {dataApplication.resources.map((resource) => (
          <Grid item xs={4} key={resource.id}>
            <LoadingButton loading={loadingGetResource} onClick={() => getFile(resource.type, resource.name)}>
              {RESOURCE_TYPE.find((value) => value.value === resource.type)?.text as string}
            </LoadingButton>
            <Typography variant="body2" color="gray">
              Subido por {resource.operator.name} {resource.operator.lastName}
            </Typography>
            {resource.description && <Typography variant="body1">{resource.description}</Typography>}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
