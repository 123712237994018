import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Backdrop, Box, CircularProgress, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { PersonType } from "../../../types/Person";
import { Field } from "../../../components/Field";
import { NotFound } from "../../layouts/NotFound";
import { UserType } from "../../../types/User";
import { CATEGORY, DNI_TYPE, PROBLEMATIC, REASON, STATUS } from "../../../assets/constants/translates";
import { convertToDate, convertToFullDate } from "../../../helpers/dateFormat";
import { ApplicationType, Category, Status } from "../../../types/Application";
import { Reason, RejectedType } from "../../../types/Rejected";
import { BaseDataGrid } from "../../../components/BaseDataGrid";

const columnsRejected: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    description: "Código de solicitud",
    width: 25,
  },
  {
    field: "createdAt",
    headerName: "Fecha",
    description: "Fecha de entrada",
    width: 100,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params: RejectedType) => {
      return new Date(params.createdAt);
    },
    renderCell: (params) => {
      const [date, hour] = convertToDate(params.row.createdAt);
      return (
        <Tooltip title={hour}>
          <span>{date}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "reason",
    headerName: "Razón",
    description: "Razón del rechazo",
    type: "string",
    minWidth: 100,
    flex: 30,
    valueGetter: (params: RejectedType) => {
      return REASON[params.reason];
    },
  },
  {
    field: "description",
    headerName: "Descripción",
    description: "Descripción del motivo del rechazo",
    minWidth: 100,
    flex: 30,
  },
  {
    field: "operator",
    headerName: "Operador",
    description: "Operador",
    type: "string",
    minWidth: 100,
    flex: 30,
    valueGetter: (params: RejectedType) => {
      const { name, lastName } = params.operator as UserType;
      return `${lastName}, ${name}`;
    },
  },
];

const columnsAccepteds: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    description: "Código de solicitud",
    width: 25,
  },
  {
    field: "createdAt",
    headerName: "Fecha",
    description: "Fecha de entrada",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params: ApplicationType) => {
      return new Date(params.createdAt);
    },
    renderCell: (params) => {
      const [date, hour] = convertToDate(params.row.createdAt);
      return (
        <Tooltip title={hour}>
          <span>{date}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "status",
    headerName: "Estado",
    description: "Estado en el que se encuentra el formulario",
    minWidth: 100,
    flex: 20,
    renderCell: (params: { row: ApplicationType }) => {
      return STATUS[params.row.status as Status];
    },
  },
  {
    field: "category",
    headerName: "Categoría",
    description: "Categoría de la solicitud",
    minWidth: 100,
    flex: 20,
    renderCell: (params: { row: ApplicationType }) => {
      return CATEGORY[params.row.category as Category];
    },
  },
  {
    field: "problematic",
    headerName: "Problemática",
    description: "Problemática de la solicitud",
    minWidth: 100,
    flex: 20,
    renderCell: (params: { row: ApplicationType }) => {
      return PROBLEMATIC.find((value) => value.value === params.row.problematic)?.text as string;
    },
  },
  {
    field: "observation",
    headerName: "Observaciones",
    description: "Observaciones de la solicitud",
    minWidth: 100,
    flex: 30,
  },
  {
    field: "amount",
    headerName: "Monto",
    description: "Monto otorgado",
    minWidth: 100,
    flex: 10,
    renderCell: (params) => {
      return `${params.row.amount ? params.row.amount : 0} ${params.row.unit || ""}`;
    },
  },
];

export const Person = () => {
  const { idPerson } = useParams();
  const { data: dataPerson, loading: loadingPerson, error: errorPerson } = useAxios<PersonType>({ url: `${endpoints.getPersonById}/${idPerson}` });

  const [resourceToGet, setResourceToGet] = useState("");
  const {
    data: resourceData,
    loading: loadingGetResource,
    refetch: getResource,
  } = useAxios<ArrayBuffer>({
    url: `${endpoints.downloadFile}/dni/${resourceToGet}`,
    method: "get",
    config: { responseType: "arraybuffer" },
    awaiting: true,
  });

  function createResponse(data: ArrayBuffer, type: string) {
    var blob = new Blob([data], { type });
    var win = window.open("", "_blank") as Window;
    var URL = window.URL || window.webkitURL;
    var dataUrl = URL.createObjectURL(blob);
    win.location = dataUrl;
  }

  useEffect(() => {
    if (resourceToGet) {
      getResource();
    }
  }, [resourceToGet]);
  useEffect(() => {
    if (resourceData) {
      const ext = resourceToGet.split(".").pop();
      let content = "";
      switch (ext) {
        case "pdf":
          content = "application/pdf";
          break;
        case "docx":
          content = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "doc":
          content = "application/msword";
          break;
        default:
          content = "image/" + ext;
          break;
      }
      createResponse(resourceData, content);
      setResourceToGet("");
    }
  }, [resourceData]);

  return loadingPerson ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : errorPerson ? (
    <NotFound />
  ) : (
    <Box width="100%">
      <Typography variant="h3">Ciudadano #{dataPerson.id}</Typography>
      <Paper sx={{ width: "100%", p: 2 }} elevation={3}>
        <Grid container spacing={2}>
          <Field description="Nombre" text={dataPerson.name} xs={5} />
          <Field description="Apellido" text={dataPerson.lastName} xs={5} />
          <Field description={DNI_TYPE.find((value) => value.value === dataPerson.dniType)?.text as string} text={dataPerson.dni} xs={2} />
          <Field description="Fecha de nacimiento" text={convertToDate(dataPerson.birthDate)[0]} xs={6} />
          <Field description="Celular" text={dataPerson.phoneNumber || "N/A"} xs={6} />
          <Field description="Alta en el sistema" text={convertToFullDate(dataPerson.createdAt)} xs={6} />
          <Field description="Última modificación" text={convertToFullDate(dataPerson.updatedAt)} xs={6} />
          {dataPerson.dniFileFront && (
            <Grid item xs={6}>
              <LoadingButton loading={loadingGetResource} onClick={() => setResourceToGet(`${dataPerson.dniFileFront}`)}>
                {dataPerson.dniFileBack ? "Frente del DNI" : "Foto del DNI"}
              </LoadingButton>
            </Grid>
          )}
          {dataPerson.dniFileBack && (
            <Grid item xs={6}>
              <LoadingButton loading={loadingGetResource} onClick={() => setResourceToGet(`${dataPerson.dniFileBack}`)}>
                Dorso del DNI
              </LoadingButton>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h4">Historial de solicitudes</Typography>
        <BaseDataGrid rows={dataPerson.applications} columns={columnsAccepteds} loading={loadingPerson} />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h4">Entradas/Solicitudes rechazadas</Typography>
        <BaseDataGrid rows={dataPerson.rejecteds} columns={columnsRejected} loading={loadingPerson} />
      </Paper>
    </Box>
  );
};
