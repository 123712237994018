import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Box, Tooltip } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { PersonType } from "../../../types/Person";
import { DNI_TYPE } from "../../../assets/constants/translates";
import { convertToDate } from "../../../helpers/dateFormat";
import { BaseDataGrid } from "../../../components/BaseDataGrid";
import { toNameFormat } from "../../../helpers/functions";

let columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    description: "Código de solicitud",
    width: 25,
  },
  {
    field: "name",
    headerName: "Nombre",
    description: "Nombre del ciudadano",
    minWidth: 100,
    flex: 40,
    renderCell: (params: { row: PersonType }) => {
      return <>{toNameFormat(params.row.name)}</>;
    },
  },
  {
    field: "lastName",
    headerName: "Apellido",
    description: "Apellidos del ciudadano",
    minWidth: 100,
    flex: 40,
    renderCell: (params: { row: PersonType }) => {
      return <>{toNameFormat(params.row.lastName)}</>;
    },
  },
  {
    field: "dni",
    headerName: "DNI",
    description: "Tipo y número de documento",
    minWidth: 100,
    flex: 10,
    renderCell: (params: { row: PersonType }) => {
      const { dniType, dni } = params.row as PersonType;
      return (
        <Tooltip title={DNI_TYPE.find((value) => value.value === dniType)?.text as string}>
          <span>{dni}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "phoneNumber",
    headerName: "Celular",
    description: "Número de celular",
    minWidth: 100,
    flex: 10,
  },
  {
    field: "createdAt",
    headerName: "Creado",
    description: "Fecha de creación",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params: PersonType) => {
      return new Date(params.createdAt);
    },
    renderCell: (params: { row: PersonType }) => {
      const [date, hour] = convertToDate(params.row.createdAt);
      return (
        <Tooltip title={hour}>
          <span>{date}</span>
        </Tooltip>
      );
    },
  },
];

export const People = () => {
  let navigate = useNavigate();
  const { data: persons, loading: loadingPersons } = useAxios<PersonType[]>({
    url: endpoints.getPersons,
    initialData: [],
  });

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate("../ciudadano/" + params.row.id);
  };

  return (
    <Box width="100%">
      <Typography variant="h3">Ciudadanos registrados</Typography>
      <Paper sx={{ width: "100%" }} elevation={3}>
        <BaseDataGrid rows={persons} columns={columns} loading={loadingPersons} onRowClick={handleRowClick} />
      </Paper>
    </Box>
  );
};
