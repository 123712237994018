import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NewRequestContextProvider } from "../contexts/NewRequestContext";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { Login } from "../views/auth/Login";
import { NotFound } from "../views/layouts/NotFound";
import { Dashboard } from "../views/Dashboard";
import { NewRequest } from "../views/NewRequest";
import { Entries } from "../views/operator/rejected/Entries";
import { Rejected } from "../views/operator/rejected/Rejected";
import { Person } from "../views/operator/person/Person";
import { Applications } from "../views/operator/application/Applications";
import { Application } from "../views/operator/application/Application";
import { Statistics } from "../views/admin/Statistics";
import { NewEntry } from "../views/operator/rejected/NewEntry";
import { People } from "../views/operator/person/People";
import { Maintenance } from "../views/layouts/Maintenance";

export const AppRouter = () => {
  const isInMaintenance = false;

  return (
    <Router>
      <React.Suspense
        fallback={
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        }
      >
        {isInMaintenance ? (
          <Routes>
            <Route path="*" element={<Maintenance />} />{" "}
          </Routes>
        ) : (
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route element={<PrivateRoute roles={undefined} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/nueva-entrada"
                element={
                  <NewRequestContextProvider>
                    <NewEntry />
                  </NewRequestContextProvider>
                }
              />
              <Route
                path="/nueva-solicitud"
                element={
                  <NewRequestContextProvider>
                    <NewRequest />
                  </NewRequestContextProvider>
                }
              />
              <Route
                path="/nueva-solicitud/:idApplication"
                element={
                  <NewRequestContextProvider>
                    <NewRequest />
                  </NewRequestContextProvider>
                }
              />
              <Route
                path="/editar/:idApplication"
                element={
                  <NewRequestContextProvider>
                    <NewRequest />
                  </NewRequestContextProvider>
                }
              />
              <Route path="/entradas" element={<Entries />} />
              <Route path="/entrada/:idRejected" element={<Rejected />} />
              <Route path="/solicitud/:idApplication" element={<Application />} />
              <Route path="/ciudadanos" element={<People />} />
              <Route path="/ciudadano/:idPerson" element={<Person />} />
              <Route path="/solicitudes" element={<Applications type="success" />} />
              <Route path="/solicitudes-pendientes" element={<Applications type="complete" />} />
              <Route path="/solicitudes-en-progreso" element={<Applications type="progress" />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/estadisticas" element={<Statistics />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}

        <ToastContainer />
      </React.Suspense>
    </Router>
  );
};
