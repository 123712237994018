interface SpanishDictionary {
  [key: string]: { [key: string]: string };
}

const SPANISH: SpanishDictionary = {
  general: { id: "ID", createdAt: "Creado", updatedAt: "Modificado" },
  user: {
    name: "Nombre/s",
    lastName: "Apellido/s",
    email: "Correo electrónico",
    role: "Tipo",
  },
  userRoles: { admin: "Administrador", operator: "Operador" },
  cohabitant: {
    name: "Nombre",
    lastName: "Apellido",
    dni: "DNI",
    birthDate: "Fecha de nacimiento",
    relationship: "Parentesco",
    education: "Nivel educativo alcanzado",
    earnings: "Ingreso mensual",
  },
} as const;

type SpanishKeys = keyof typeof SPANISH;

export function toSpanish(entity: SpanishKeys, word: string) {
  if (SPANISH.hasOwnProperty(entity)) {
    return SPANISH[entity][word] || word;
  } else {
    return word;
  }
}
