import { Category, Category_N, Category_S, Status } from "../../types/Application";
import { Reason } from "../../types/Rejected";

//REJECTED
export const REASON: Record<Reason, string> = {
  0: "Entrada",
  1: "Falta de insumos",
  2: "Demasiados pedidos hechos",
  3: "Última solicitud no rendida",
  4: "No corresponde a la secretaría",
  5: "Otro",
};

//APPLICATION
export const STATUS: Record<Status, string> = {
  in_cohabitants: "Carga de grupo familiar pendiente",
  in_form_dwelling: "Datos de la vivienda pendiente",
  in_note: "Nota pendiente",
  in_app_data: "Datos de la solicitud pendientes",
  in_files: "Carga de archivos pendiente",
  complete: "Completada, falta rendición",
  success: "Finalizada",
};
export const PROBLEMATIC = [
  { value: "not_applicable", text: "No aplica" },
  { value: "low_income", text: "Bajos recursos" },
  { value: "for_benefit", text: "Para beneficio" },
  { value: "disease", text: "Enfermedad" },
  { value: "disability", text: "Discapacidad" },
  { value: "underweight", text: "Bajo peso" },
  { value: "gender_violence", text: "Violencia de género" },
  { value: "health", text: "Salud" },
  { value: "pregnancy", text: "Embarazo" },
  { value: "anses", text: "ANSES" },
  { value: "judiciary", text: "Poder Judicial" },
  { value: "upi", text: "UPI" },
  { value: "university", text: "Estudio terciario y/o Universitario" },
  { value: "police", text: "Aspirante para la Policía del Chaco" },
];
export const CATEGORY: Record<Category, string> = {
  im_waiting: "Te espero",
  birthday: "Cumpleaños",
  fuel: "Combustible",
  commodity: "Mercadería",
  spare_parts: "Respuestos",
  water_tank: "Tanque de agua",
  glasses: "Anteojos",
  terrain: "Terrenos",
  medication: "Medicamentos",
  tickets: "Pasajes",
  burial: "Sepelio",
  various_supplies: "Insumos varios",
  other: "Otro",
  s_transport: "Transporte (subsidio)",
  s_electricity: "Luz (subsidio)",
  s_medication: "Medicamento (subsidio)",
  s_birthday: "Cumpleaños (subsidio)",
  s_gas: "Gas (subsidio)",
  s_medical_tests: "Estudios médicos (subsidio)",
  s_building_materials: "Materiales de construcción (subsidio)",
  s_entrepreneurial_equipment: "Equipamiento para emprendimiento (subsidio)",
  s_rent_payment: "Pago de alquiler (subsidio)",
  s_other: "Otro (subsidio)",
};
export const CATEGORY_N: Record<Category_N, string> = {
  im_waiting: "Te espero",
  birthday: "Cumpleaños",
  fuel: "Combustible",
  commodity: "Mercadería",
  spare_parts: "Respuestos",
  water_tank: "Tanque de agua",
  glasses: "Anteojos",
  terrain: "Terrenos",
  medication: "Medicamentos",
  tickets: "Pasajes",
  burial: "Sepelio",
  various_supplies: "Insumos varios",
  other: "Otro",
};
export const CATEGORY_SUB: Record<Category_S, string> = {
  s_transport: "Transporte",
  s_electricity: "Luz",
  s_medication: "Medicamento",
  s_birthday: "Cumpleaños",
  s_gas: "Gas",
  s_medical_tests: "Estudios médicos",
  s_building_materials: "Materiales de construcción",
  s_entrepreneurial_equipment: "Equipamiento para emprendimiento",
  s_rent_payment: "Pago de alquiler",
  s_other: "Otro",
};

//RESOURCE
export const RESOURCE_TYPE = [
  { value: "dni_front", text: "DNI/Frente del DNI" },
  { value: "dni_back", text: "Dorso del DNI" },
  { value: "budget", text: "Presupuesto" },
  { value: "prescription", text: "Receta médica" },
  { value: "other", text: "Otro" },
  { value: "receipt", text: "Comprobante" },
];

//PERSON
export const DNI_TYPE = [
  { value: "dni", text: "DNI" },
  { value: "passport", text: "Pasaporte" },
  { value: "enrollment_notebook", text: "Libreta de enrolamiento" },
  { value: "civil_notebook", text: "Libreta civil" },
  { value: "other", text: "Otro" },
];

//FORM
export const MARITAL_STATUS = [
  { value: "single", text: "Soltero/a" },
  { value: "married", text: "Casado/a" },
  { value: "widowed", text: "Viudo/a" },
  { value: "divorced", text: "Divorciado/a" },
  { value: "cohabitating", text: "En unión libre" },
  { value: "engaged", text: "Comprometido/a" },
];
export const EDUCATION = [
  { value: "no_formal_schooling", text: "Sin escolarización formal" },
  { value: "incomplete_primary", text: "Primaria en curso/incompleta" },
  { value: "complete_primary", text: "Primaria completa" },
  { value: "incomplete_secondary", text: "Secundario en curso/incompleto" },
  { value: "complete_secondary", text: "Secundario completo" },
  { value: "incomplete_tertiary", text: "Terciario en curso/incompleto" },
  { value: "complete_tertiary", text: "Terciario completo" },
  { value: "incomplete_university", text: "Universitario en curso/incompleto" },
  { value: "complete_university", text: "Universitario completo" },
  { value: "other", text: "Otro" },
];
export const MATERIAL = [
  { value: "material", text: "Material" },
  { value: "clay", text: "Barro" },
  { value: "tent", text: "Carpa" },
  { value: "other", text: "Otro" },
];
export const ROOF = [
  { value: "metal", text: "Chapa" },
  { value: "metal_and_cardboard", text: "Chapa-cartón" },
  { value: "ceiling", text: "Cielorraso" },
  { value: "other", text: "Otro" },
];
export const TENURE = [
  { value: "owned", text: "Propia" },
  { value: "given", text: "Cedida" },
  { value: "borrowed", text: "Prestada" },
  { value: "rented", text: "Alquilada" },
];
export const WATER_SUPPLY = [
  { value: "potable_water", text: "Agua potable" },
  { value: "public_faucet", text: "Canilla pública" },
];
export const HEAT_SOURCE = [
  { value: "gas_stove", text: "Cocina a gas" },
  { value: "wood_stove", text: "Cocina a leña" },
  { value: "kerosene", text: "Kerosene" },
  { value: "other", text: "Otro" },
];

//COHABITANT
export const RELATIONSHIP = [
  { value: "spouse", text: "Cónyuge" },
  { value: "partner", text: "Concubino/a" },
  { value: "father", text: "Padre" },
  { value: "mother", text: "Madre" },
  { value: "child", text: "Hijo/a" },
  { value: "sibling", text: "Hermano/a" },
  { value: "grandparent", text: "Abuelo/a" },
  { value: "grandchild", text: "Nieto/a" },
  { value: "uncle", text: "Tío/a" },
  { value: "cousin", text: "Primo/a" },
  { value: "nephew", text: "Sobrino/a" },
  { value: "parent_in_law", text: "Suegro/a" },
  { value: "child_in_law", text: "Yerno/a" },
  { value: "other", text: "Otro" },
];
