import React, { ReactElement } from "react";
import { Tooltip } from "@mui/material";
import { MdOutlineFiberNew, MdOutlinePostAdd } from "react-icons/md";
import { BiBarChartSquare, BiArchive, BiLogOut } from "react-icons/bi";
import { LuCalendarClock } from "react-icons/lu";
import { RxTimer } from "react-icons/rx";
import { AiOutlineFileDone } from "react-icons/ai";
import { RiGroupLine } from "react-icons/ri";

import "../../assets/styles/sidebar.css";
import { RolesType } from "../../types/User";
import { userData } from "../../helpers/auth";
// import loginImg from "../../assets/images/desarrollo.svg";
// import logoSaenzPenia from "../../assets/images/logoSaenzPenia.png";

interface OptionsType {
  icon: ReactElement<any, any>;
  description: string;
  roles: RolesType[];
  link: string;
}

export const Sidebar = () => {
  const toggleSidebar = () => {
    (document.querySelector("nav") as HTMLElement).classList.toggle("close");
  };

  // const toggleMode = () => {
  //   const body = document.querySelector("body") as HTMLBodyElement;
  //   body.classList.toggle("dark");

  //   if (body.classList.contains("dark")) {
  //     (document.querySelector(".mode-text") as Element).innerHTML = "Modo claro";
  //   } else {
  //     (document.querySelector(".mode-text") as Element).innerHTML = "Modo oscuro";
  //   }
  // };

  const user = userData();

  const options: OptionsType[] = [
    {
      icon: <MdOutlinePostAdd />,
      description: "Nueva entrada",
      roles: ["operator"],
      link: "/nueva-entrada",
    },
    {
      icon: <MdOutlineFiberNew />,
      description: "Nueva solicitud",
      roles: ["operator"],
      link: "/nueva-solicitud",
    },
    {
      icon: <BiArchive />,
      description: "Entradas",
      roles: ["admin", "operator"],
      link: "/entradas",
    },
    {
      icon: <RxTimer />,
      description: "Solicitudes en progreso",
      roles: ["admin", "operator"],
      link: "/solicitudes-en-progreso",
    },
    {
      icon: <LuCalendarClock />,
      description: "Solicitudes pendientes",
      roles: ["admin", "operator"],
      link: "/solicitudes-pendientes",
    },
    {
      icon: <AiOutlineFileDone />,
      description: "Solicitudes finalizadas",
      roles: ["admin", "operator"],
      link: "/solicitudes",
    },
    {
      icon: <RiGroupLine />,
      description: "Ciudadanos",
      roles: ["admin", "operator"],
      link: "/ciudadanos",
    },
    {
      icon: <BiBarChartSquare />,
      description: "Estadísticas",
      roles: ["admin"],
      link: "/estadisticas",
    },
    // {
    //   icon: "bx-calendar",
    //   description: "Calendario",
    //   roles: ["admin", "operator"],
    //   link: "/calendario",
    // },
  ];

  return (
    <nav className="sidebar close">
      <header>
        <div className="image-text">
          {/* <span className="image"><img src={loginImg} /></span> */}
          <div className="text header-text">
            <span className="name">Secretaria de Desarrollo Social</span>
          </div>
        </div>
        <i className="bx bx-chevron-right toggle" onClick={toggleSidebar}></i>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            {options.map((option) => {
              if (option.roles.includes(user.role)) {
                return (
                  <Tooltip title={option.description} key={option.link} placement="right" disableInteractive arrow>
                    <li className="nav-link">
                      <a href={option.link}>
                        {option.icon}
                        <span className="text nav-text">{option.description}</span>
                      </a>
                    </li>
                  </Tooltip>
                );
              } else {
                return <React.Fragment key={option.link} />;
              }
            })}
          </ul>
        </div>
        <div className="bottom-content">
          <Tooltip title="Cerrar sesión" placement="right" disableInteractive arrow>
            <li className="nav-link">
              <a
                href="#salir"
                onClick={() => {
                  localStorage.removeItem("jwt");
                  window.location.replace("/");
                }}
              >
                <BiLogOut />
                <span className="text nav-text">Cerrar sesión</span>
              </a>
            </li>
          </Tooltip>

          {/* <li className="mode">
            <div className="moon-sun">
              <i className="bx bx-moon icon moon"></i>
              <i className="bx bx-sun icon sun"></i>
            </div>
            <span className="mode-text text">Modo oscuro</span>

            <div className="toggle-switch" onClick={toggleMode}>
              <span className="switch"></span>
            </div>
          </li> */}
        </div>
      </div>
    </nav>
  );
};
